.wallet{
	&-container {
		background: $dark_black;
		.wallet-wrapper{
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			img{
				width: 80px;
				margin-bottom: 20px;
			}
			h1{
				font-weight: bold;
				font-size: 24px;
				color: #fff;
				line-height: normal;
				display: inline-block;
				text-align: center;
				margin-bottom: 20px;
				span{
					display: flex;
					flex-direction: column;
				}
			}
			p{
				color: #fff;
				text-align: center;
				display: inline-block;
				margin-bottom: 50px;
				span{
					display: flex;
					flex-direction: column;
				}
			}
			.wallet-buttons{
				display: flex;
				flex-direction: column;
				width: 250px;
				button {
					margin: 10px 0px;
					padding: 15px;
					text-align: center;
					border-radius: 40px;
					font-size: 16px;
					cursor: pointer;
					border: none;
					color: white;
					transition: all 0.2s;
					background: radial-gradient(
						100% 715.74% at 100% 49.35%,
						#004fff 0%,
						#cb06ed 33.33%,
						#ff0062 68.23%,
						#ff8500 100%
					);
					&:hover {
						color: $dark_black;
						box-shadow: 0px 0px 40px rgb(214 34 255 / 60%);
					}
				}
			}
		}
	}
}