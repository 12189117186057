
.contents{
	margin: 0;
	padding: 0;
	background: $light_blue;
	height: 100%;
	min-height: 100vh;
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 2;
	&::before{
		position: absolute;
		content: "";
		background-image: url(../../assets/images/Color44.png);
		background-size: contain;
		background-repeat: no-repeat;
		top: 50%;
		left: 0px;
		right: 0px;
		bottom: 0px;
		text-align: center;
		background-position: center;
		margin: -365px auto 280px;
		z-index: -1;

	}
	.mb100{
		margin-bottom: 60px;
	}
	.title {
		font-weight: bold;
		font-size: 24px;
		color: #fff;
		line-height: normal;
		display: inline-block;
		text-align: center;
	}
	p{
		color: #fff;
		text-align: center;
		margin-bottom: 0px;
		display: inline-block;
	}
	.mb20{
		margin-bottom: 20px;
	}
	.sign-in-btn {
		@include submit-btn()
	}
	a{
		text-decoration: none;
	}
	.contain {
		width: 300px;
		margin: 0 auto;
	}
}