.modalOverlay{
    background: rgba(5, 5, 57, 0.8);
}
.commonModal{
    border-radius: 20px;
	background: #000000;
    width: 450px;
    max-width: 100%;
    @include respond-at-max(540px){
        width: auto;
        height: auto;
    }
    .modalContainer{
        .header{
            h3{
                font-weight: bold;
                font-size: 30px;
                line-height: 37px;
                color: white;
            }
            p{
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: white;
            }
            button{
                display: flex;
                margin: 50px auto;
                align-items: center;
                padding: 10px 15%;
                border-radius: 40px;
                font-size: 16px;
                cursor: pointer;
                border: none;
                transition: all 0.2s;
                background: radial-gradient(
                    100% 715.74% at 100% 49.35%,
                    #004fff 0%,
                    #cb06ed 33.33%,
                    #ff0062 68.23%,
                    #ff8500 100%
                );
                    &:hover {
                        color: #00001e;
                        box-shadow: 0px 0px 40px rgb(214 34 255 / 60%);
                    }
            }
            a{
                color: white;
                text-decoration: none;
            }
        }
        .info{
            margin-top:50px;
            &-main{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: white;
                margin: 10px 0;
                .cursor{
                    cursor: pointer;
                }
            }
            &-group{
                margin:40px 0;
                p{
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    color: white;  
                }
                &-main{
                    display: flex;
                    justify-content: space-between;
                    .input-section{
                        width:100%;
                        display: flex;
                        border: 2px solid white;
                        border-radius: 40px;
                        padding: 4px;
                        height: 57px;
                        input {
                            width:100%;
                            background: transparent;
                            border:none;
                            padding-left: 10px;
                            color: white;
                            &:focus, &:active {
                                outline: none;
                                border: none;
                            }
                        }
                    }
                    .button-section{
                        width:40%;
                        border-radius:40px;
                        padding: 0 20px;
                        margin: 0 auto;
                        border:none;
                        background: white;
                        transition: all 0.2s;
                        &:hover {
                            color:white;
                            background: radial-gradient(
                                100% 715.74% at 100% 49.35%,
                                #004fff 0%,
                                #cb06ed 33.33%,
                                #ff0062 68.23%,
                                #ff8500 100%
                            );
                        }
                        @media (max-width: 320px) {
                            padding: 0 15px ;
                            font-size: 14px ;
                        }
                        &-disabled{
                            width:40%;
                            border-radius:40px;
                            padding: 0 20px;
                            margin: 0 auto;
                            color:$field_color;
                            border:2px solid $field_color;
                            background: transparent;
                            transition: all 0.5s;
                            cursor: auto;
                            @media (max-width: 320px) {
                                padding: 0 15px ;
                                font-size: 14px ;
                            }
                        }
                    }
                }
                &-error{
                    &_redmax {
                        background: linear-gradient(90deg, #ec0297 0%, #ff204c 70%);
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }  

        .mainInput{
            display: flex;
            justify-content: center;
            border-radius: 40px;
            height: 60px;
            margin: 60px 0 35px 0;
            padding:2px;
            background-image: linear-gradient(
            90deg,
            #004fff 0%,
            #cb06ed 33.3%,
            #ff0062 65.56%,
            #ff8500 99.9%
            );
            input {
            width:100%;
            border-radius: 40px; 
            border: none;
            padding: 0 20px;
            color: white;
            font-size: 16px;
			line-height: 21px;
			font-weight: normal;
            font-style: normal;
            background: #000000;
                &:focus, &:active {
                    outline: none;
                    border: none;
                }
            }
        }
        .secondary-content{
            display: block;
            margin: 10px;
            text-align: center;
            font-weight: normal;
            font-size: 14px;
            color: white;
        }
        .active{
            display:block;
            margin: 40px auto;
            padding: 10px 15%;
            height: 57px;
            border-radius: 40px;
            font-size: 16px;
            cursor: pointer;
            color: white;
            border: 0px;
            transition: all 0.2s;
            background: radial-gradient(
                100% 715.74% at 100% 49.35%,
                #004fff 0%,
                #cb06ed 33.33%,
                #ff0062 68.23%,
                #ff8500 100%
            );
            &:hover {
                color: #00001e;
                box-shadow: 0px 0px 40px rgb(214 34 255 / 60%);
            }
        }
        .disabled{
            display:block;
            margin: 40px auto;
            padding: 10px 15%;
            height: 57px;
            border-radius: 40px;
            font-size: 16px;
            color: $field_color;
            border: 2px solid $field_color;
            background: transparent;
            cursor: auto;
        }
    }
}
.customOverlay{
    background: rgba(0, 0, 0, 0.9);
}
.customModal{
    border-radius: 20px;
	background: #000000;
    border:solid 1px white;
    width: 450px;
    max-width: 100%;
    @include respond-at-max(540px){
        width: auto;
        height: auto;
    }
    .modalContainer{
            .header{
                h3{
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 37px;
                    color: white;
                }
                p{
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: white;
                }
                button{
                    display: flex;
                    margin: 50px auto 20px auto;
                    align-items: center;
                    padding: 10px 15%;
                    border-radius: 40px;
                    font-size: 16px;
                    cursor: pointer;
                    transition: all 0.2s;
                    color: white;
                    background: radial-gradient(
                        100% 715.74% at 100% 49.35%,
                        #004fff 0%,
                        #cb06ed 33.33%,
                        #ff0062 68.23%,
                        #ff8500 100%
                    );
                    border: none;
                        &:focus, &:active {
                            outline: none;
                            border: none;
                        }
                        &:hover {
                            color: #000000;
                            box-shadow: 0px 0px 40px rgb(214 34 255 / 60%);
                            border: none;
                        }
                }
            }
            
        }
    }