.metamask {
  &-network {
    &-wrapper {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 1000;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 30, 0.9);
      overflow-y: auto;
      display: flex;
      cursor: default;
    }

    &-alignBox {
      margin: auto;
      min-width: 220px;
      max-width: 500px;
      width: 95%;
    }

    &-container {
      margin: 18px;
      padding: 25px;
      background-color: #14142E;
      border-radius: 12px;
      text-align: center;

      &_error {
        border: solid 2px transparent;
        border-radius: 10px;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
          linear-gradient(101deg, #ff8500, #ff0062);
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 2px 1000px 1px #00001e inset;
      }
    }

    &-text {
      color: white;
      font-weight: bolder;
      font-size: 16px;
    }
  }
}
.mb-collapse-button {
  position: absolute;
  right: 10px;
  top: 15px;
}