//**************** MEDIA QUERIRES MIXINS *************//

@mixin respond-at-max($media) {
  @media only screen and (max-width: $media) {@content;}  
}
@mixin respond-at-min($media) {
  @media only screen and (min-width: $media) {@content;}  
}
@mixin respond-at($media) {
  @media only screen and (width: $media) {@content;}  
}

//**************** COLORS *************//
$dark_black:#070714;
$dark_blue: #05043E;
$light_blue: #061645;
$gray: #76759C;
$light_gray: #F8F8F8;
$field_bg: #070714;
$field_color: rgba(255, 255, 255, 0.4);



//**************** MIXINS *************//
@mixin input-field() {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin : 0 2px;
	border: none;
	border-radius: 40px;
	padding: 20px;
	color: $field_color;
	font-weight: bold;
	font-style: normal;
	background: $dark_black;
	&::-webkit-input-placeholder {
		color: $field_color;
	}
	&::-moz-placeholder {
	    color: $field_color;
	}
	/* Firefox 19+ */
	&:-moz-placeholder {
	    color: $field_color;
	}
	/* Firefox 18- */
	&:-ms-input-placeholder {
	    color: $field_color;
	}
	&:focus, &:active {
	    outline: none;
	    border: none;
	}
}

@mixin submit-btn() {
	display: inline-block;
	text-align: center;
	align-items: center;
	justify-content: center;
	line-height: 24px;
	padding: 16px 40px;
	width: 287px;
	height: 64px;
	max-width: 100%;
	border-radius: 49px;
	font-weight: bold;
	font-size: 20px;
	border: 4px solid #F29FFF;
	background: -webkit-linear-gradient(180deg, #F29FFF 0%, #878CFF 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: #878CFF;
	cursor: pointer;
	/*color: linear-gradient(180deg, #F29FFF 0%, #878CFF 100%);*/
}

@mixin btn-primary() {
	display: inline-block;
	justify-content: center;
	border-radius: 40px;
	cursor: pointer;
	color: white;
	border: 0px;
	transition: all 0.2s;
	background: radial-gradient(
		100% 715.74% at 100% 49.35%,
		#004fff 0%,
		#cb06ed 33.33%,
		#ff0062 68.23%,
		#ff8500 100%
	);
	&:hover {
		color: #00001e;
		box-shadow: 0px 0px 40px rgb(214 34 255 / 60%);
	}
}