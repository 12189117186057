@import './base.scss';

.rootDisclaimer {
  padding-top: 2.4rem !important;
  // padding-bottom: 6.4rem;

  .disclaimer {
    background: rgba(242, 164, 0, 0.1);
    border: 1px solid #f2a400;
    border-radius: 0.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    color: #f2a400;
    font-size: 1rem;
    padding: 1.6rem;

    .warning {
      display: none;
      align-items: center;
      padding: 0 4rem 0 2.4rem;

      .warningIcon {
        height: 2rem;
        width: auto;

        path {
          fill: #f2a400;
        }
      }

      .warningLabel {
        // font-family: $font-bold;
        font-weight: bold;
        margin-left: 0.8rem;
      }

      @include lg {
        display: flex;
      }
    }

    .message {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 2.4rem 0 4rem;

      @include lg {
        border-left: 1px solid #f2a400;
      }

      .link {
        margin-left: 0.2rem;
        text-decoration: underline;

        &:hover {
          color: $color-contrast;
        }
      }

      .separator {
        margin-left: 1.2rem;
      }
    }
  }
}

.root {
  @include lg {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 0 !important;
  }
}

.mobileTitle {
  width: 100%;
  color: $color-contrast;
  font-family: $font-bold;
  font-size: 3.2rem;
  margin: 0;
  text-align: center;

  @include lg {
    display: none;
  }
}

.mobileDescription {
  width: 100%;
  color: $color-primary400;
  font-family: $font-bold;
  font-size: 1.6rem;
  margin-top: 1.6rem;
  padding: 0 1.6rem;
  text-align: center;

  @include lg {
    display: none;
  }
}

