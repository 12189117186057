.sign-in-wrapper {
	position: relative;
	background: $dark_black;
	.sign-in-form {
		margin: 0;
		padding: 0;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.form-wrapper {
			@include respond-at-max(576px) {
				padding: 85px 20px;
			}
			width: 100%;
			max-width: 532px;
			margin: 0 auto;
			.title {
				color: #ffffff;
				font-style: normal;
				font-weight: bold;
				font-size: 50px;
				line-height: 65px;
				margin-bottom: 50px;
				text-align: center;
			}
			.subtitle {
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 29px;
				color: #ffffff;
				text-align: center;
			}
			.fields-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.field-item {
					margin: 15px 0;
					background-image: linear-gradient(
						90deg,
						#004fff 0%,
						#cb06ed 33.3%,
						#ff0062 65.56%,
						#ff8500 99.9%
					);
					border-radius: 40px;
					height: 61px;
					display: flex;
					justify-content: center;
					align-items: center;
					input {
						@include input-field();
						height: 57px;
						font-size: 16px;
						line-height: 21px;
						color:white;
					}
					.error {
						color: yellow;
						font-size: 12px;
					}
				}
				.error {
					color: yellow;
					font-size: 12px;
				}
			}
			.login-btn {
				@include btn-primary();
				margin: 15px auto;
				padding: 10px 25%;
				height: 57px;
				font-size: 16px;
			}
			.forgot-password-text {
				text-align: center;
				color: white;
				cursor: pointer;
				padding: 15px 0;
				transition: all 0.2s;
				&:hover {
					font-weight: bold;
					transform: scale(1.02);
				}
			}
			.create-account{
				text-align: center;
				color: white;
				cursor: pointer;
				padding: 15px;
				a {
					transition: all 0.2s;
					text-decoration: underline;
					color: white;
				}
				a:hover {
					font-weight: bold;
					transform: scale(1.02);
				}
			}
		}
	}
}
