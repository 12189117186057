.sign-up-wrapper {
	position: relative;
	background: $dark_black;
	.sign-up-form {
		margin: 0;
		padding: 0;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.form-wrapper {
			@include respond-at-max(576px) {
				padding: 85px 20px;
			}
			width: 100%;
			max-width: 532px;
			margin: 0 auto;
			.title {
				color: #ffffff;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 29px;
				text-align: center;
			}
			.fields-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.field-item {
					margin: 15px 0;
					background-image: linear-gradient( 
					90deg,
					#004FFF 0%,
					#CB06ED 33.3%, 
					#FF0062 65.56%, 
					#FF8500 99.9%);
					border-radius: 40px;
					height: 61px;
					display: flex;
					justify-content: center;
					align-items: center;
					input {
						@include input-field();
						height: 57px;
						font-size: 16px;
						line-height: 21px;
						color:white;
					}
					.error {
						color: yellow;
						font-size: 12px;	
					}
				}
				.error {
					color: yellow;
					font-size: 12px;	
				}
				.radion-btns {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;
					margin: 15px 0px;
					@include respond-at-max(540px) {
						flex-direction: column;
						justify-content: center;
					}
					.cgu, .saft {
						display: flex;
						flex-direction: row;
						align-items: center;
						.label {
							font-weight: bold;
							font-size: 12px;
							line-height: 15px;
							text-decoration-line: underline;
							// color: $gray;
							color: white;
							margin-left: 10px;
							cursor: pointer;

							a {
								text-decoration: none;
								color: white;
							}
							a:hover {
								color: lightblue;
							}
						}
					}
					.saft {
						margin-left: 25px;
						@include respond-at-max(540px) {
							margin-left: 0px;
							margin-top: 10px;
						}
					}
				}
			}
			.login-btn {
				@include btn-primary();
				margin: 15px auto;
				padding: 10px 25%;
				height: 57px;
				font-size: 16px;
			}
			.account-created-link{
				text-align: center;
				color: white;
				cursor: pointer;
				padding: 15px;
				a {
					transition: all 0.2s;
					text-decoration: underline;
					color: white;
				}
				a:hover {
					font-weight: bold;
					transform: scale(1.02);
				}
			}
		}
	}
}