.card-primary {
  background-image: linear-gradient(90deg, rgba(11, 75, 254, .2) 0%, rgba(184, 13, 239, .2) 99.9%);
}
.card-claim-outline {
  border: 2px solid rgba(20, 255, 198, 0.7);
  border-radius: 18px;
}
.card-claim-empty-outline {
  border: 2px dashed rgba(255, 255, 255, .5);
  border-radius: 18px;
}
.card-secondary-outline {
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 18px;
}
.card-primary-100 {
  background-image: linear-gradient(90deg, rgba(11, 75, 254, .1) 0%, rgba(184, 13, 239, .1) 99.9%);
}
.card-secondary {
  background-image: linear-gradient(90deg, rgba(255, 0, 255, .2) 0%, rgba(236, 2, 151, .2) 99.9%);
}