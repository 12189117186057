.footer {
  @apply px-12 py-2 bg-custom-primary
}
.custom-y-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.select-lang-wrapper {
  padding: 0px 0px;
  display: flex;
  img{
    width:24px;
    height:24px;
  }
  select{
    border: none;
    background: transparent;
    outline: none;
    color: #FFF;
    font-size: 16px;
    margin: 0px 5px;
    option {
      color: black;
    }
  }
}