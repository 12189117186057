.loader-wrapper {
	position: absolute;
  z-index: 2000;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}
.btn {
  max-width: 450px;
  background: chocolate;
  padding: 15px;
}
.btn-primary {
  background: linear-gradient(90deg, rgba(0, 79, 255, 1), rgba(203, 6, 237, 1), rgba(255, 0, 98, 1), rgba(255, 133, 0, 1));
  border-style: none;
  color: white;
  &:hover {
    color: $dark_black;
    box-shadow: 0px 0px 40px rgb(214 34 255 / 60%);
  }
}

.btn-claim {
  border-radius: 30px;
  padding: 5px 20px;
  align-items: center;
  text-align: center;
  background: linear-gradient(90deg, rgba(11, 75, 254, .3) 0%, rgba(184, 13, 239, .3) 99%);
}
.btn-block {
  width: 100%;
  max-width: none;
  margin: auto;
}
.btn-rounded {
  border-radius: 50px;
}
.text-gradient {
  background: linear-gradient(90deg, #0B4BFE 0%, #B80DEF 99.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.airdrop-body {
  .collapse {
    &-disable {
      max-height: 0px;
    }
  }
}
.ternoart-body {
  .collapse {
    &-disable {
      max-height: 0px;
    }
  }
}
.accordion {
  transition: max-height .6s ease-out;
  overflow: hidden;
}

.cursor {
  &-pointer{
    cursor: pointer;
  }
}