.header{
	&-container {
		min-height: 80px;
		position: absolute;
		z-index: 1000;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 50px;
		width: 100%;
		@media (max-width: 576px) {
			padding: 10px;
		}
		&-logo {
			display: flex;
			align-items: center;
			img {
				width: 120px;
				height: 35px;
				@media (max-width: 576px) {
					width: 100px;
				}
			}
		}
		.select-lang-wrapper {
			padding: 0px 20px;
			display: flex;
			select{
				border: none;
				background: transparent;
				outline: none;
				color: #FFF;
				font-weight: 300;
				font-size: 16px;
				margin: 0px 5px;
				option {
					color: black;
				}
			}
		}
		&-account {
			display: flex;
			flex-direction: row;
			align-items: center;
			.menu-item-header {
				font-size: 14px;
				padding: 7.5px 35px;
				border-radius: 40px;
				cursor: pointer;
				color: white;
				transition: all 0.2s;
				background-image: linear-gradient(rgba(236, 2, 151, 1),rgba(255, 32, 76, 1));
				&:hover {
					box-shadow: none;
					font-size: 16px;
				}
				@media (max-width: 640px) {
					padding: 7.5px 20px !important;
				}
				@media (max-width: 330px) {
					padding: 7.5px 10px !important;
					font-size: 14px !important;
				}
				@media (max-width: 320px) {
					padding: 4px 10px !important;
					font-size: 12px !important;
				}
			}
		}
	}
	&-testnet{
		border: solid 1px #14ffc6;
		border-radius: 50px;
		color:#14ffc6;
		font-size: 12px;
		div{
			background: #14ffc6;
		}
		&:hover{
			background: #14ffc6;
			color:#070714;
			div{
				background: #070714;
			}
		}
		@media (max-width: 320px) {
			display:none;
		}
	}
}




