.thanks-hero{
	@media (max-width: 320px) {
		margin-bottom: 18rem;
	}
}
.merci{
	position: relative;
	.thanks-container{
		margin: auto;
		height: auto;
		text-align: center;
		color: white;
	}
	.thanks-card {
		margin-top: 50px;
		max-width: 600px;
		padding: 1px;
		position: relative;
		background: linear-gradient(to right, red, purple);
		border-radius: 50px;
	}
	.thanks-card-container {
		@apply bg-custom-primary;
		border-radius: 50px;
	}
}