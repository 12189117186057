@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'containers/variables';
@import 'containers/cards';
@import 'containers/signIn';
@import 'containers/signUp';
@import 'containers/header';
@import 'containers/default';
@import 'containers/nftWallet';
@import 'containers/modals';
@import 'containers/common';
@import 'containers/merci';
@import 'containers/wallet';
@import 'containers/footer';

