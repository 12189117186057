$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;

$color-invertedContrast: #0e0e23;
$color-contrast: #ffffff;
$color-danger300: #de136d;
$color-danger500: #b51b62;
$color-neutral100: #ffffff26;
$color-neutral300: #ffffff80;
$color-neutral900: #191939;
$color-primary100: #26264c;
$color-primary300: #9f9fff66;
$color-primary400: #9c9cba;
$color-primary500: #004fff;
$color-success300: #45eb87;
$color-success500: #2ea365;

$color-gradient500: linear-gradient(90deg, #0b4bfe 0%, #b80def 99.9%);
$color-gradient700: linear-gradient(90deg, #0d4bfe 0%, #7824f5 100%);
$color-radialGradient: radial-gradient(rgba(120, 36, 245, 0.1) 30%, $color-invertedContrast 90%);

$font-regular: 'Airbnb Cereal App Book';
$font-light: 'Airbnb Cereal App Light';
$font-bold: 'Airbnb Cereal App Bold';

@mixin xs() {
  @media screen and (min-width: $breakpoint-xs) {
    @content;
  }
}

@mixin sm() {
  @media screen and (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin md() {
  @media screen and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg() {
  @media screen and (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin xl() {
  @media screen and (min-width: $breakpoint-xl) {
    @content;
  }
}

@mixin xxl() {
  @media screen and (min-width: $breakpoint-xxl) {
    @content;
  }
}
